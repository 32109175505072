import React from 'react';
import ReactDOM from 'react-dom';

function HomePage() {
  return (
    <div className="home">
      <h1>Home Page</h1>
    </div>
  );
}

export default HomePage;
